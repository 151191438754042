@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-steps/scss/bootstrap-steps";

@import "fonts";
@import "variables";
@import "nav";

@import "slick-custom";

* {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004) !important;
	background: $background-color;
	color: $grey;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: $poppins;
	font-weight: $font-weight-semibold;
	color: $white;
}

h2 {
	font-size: 36px;
	margin-bottom: 30px;
}

p {
	font-family: $poppins;
	font-weight: $font-weight-regular;
	color: $grey;
	line-height: 1.8;
}

a {
	color: $purple;
	opacity: 1;
	text-decoration: none;
	transition: opacity 0.15s ease-in-out, color 0.15s, background-color 0.15s ease-in-out;
	word-break: break-all;

	&:hover {
		opacity: 0.8;
		font-weight: 400;
		color: $purple;
	}
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5);
}

.bg-purple {
	background-color: #6f42c1;
}

.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

@media (min-width: $screen-sm-min) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

.container {
	@media (min-width: 992px) {
		max-width: 960px;
	}
	@media (min-width: 1200px) {
		max-width: 1140px;
	}
}

/* ether-cards-css */

main {
	margin-top: 100px;
}

section {
	// @media (min-width: $screen-sm-min) {
	//   padding: 25px 0;
	// }

	// padding: 20px 0;

	.info-text {
		padding: 40px 0;

		@media (min-width: $screen-sm-min) {
			padding: 110px 0;
		}
	}

	&#header {
		padding: 30px 0;
		.slick-dots {
			bottom: 20px !important;
		}
		.slick-list {
			height: 100%;
		}
	}

	&#news,
	&#videos {
		/* the slides */
		.slick-slide {
			margin-right: 30px;
		}
		/* the parent */
		.slick-list {
			margin-left: 0;
		}
		.card {
			height: 520px !important;
			padding: 20px !important;
			overflow: visible !important;
		}
		.card-body {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: 0;
			padding-top: 180px !important;
			overflow: hidden;

			a {
				margin-top: auto;
				margin-right: auto !important;
			}
		}
		.card-img-top {
			background: #605e60;
			overflow: hidden;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			object-fit: cover !important;
			position: absolute;
			top: 0;
			left: 0;
			height: 160px;
			width: 100%;
		}
		.card-title {
			display: block;
			font-size: 16px;
			font-weight: 600;

			/* Fallback for non-webkit */
			display: -webkit-box;
			height: 60px;
			text-align: left;

			/* Fallback for non-webkit */
			line-height: 1.4;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.card-text {
			text-align: left;
			display: block;
			margin-bottom: 0;

			/* Fallback for non-webkit */
			display: -webkit-box;
			text-align: left;

			/* Fallback for non-webkit */
			line-height: 1.4;
			-webkit-line-clamp: 7;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

.text-m-r {
	/*middle-right*/
	text-align: right !important;

	@media (max-width: $screen-xs-max) {
		text-align: center !important;
	}
}

.text-m-l

/*middle-left*/ {
	text-align: left !important;

	@media (max-width: $screen-xs-max) {
		text-align: center !important;
	}
}

.btn-purple {
	border: 2px solid $purple;
	border-radius: 8px;
	color: $white;
	text-transform: uppercase;
	padding: 10px 20px;
	font-size: 0.8rem;

	&:hover,
	&:active {
		cursor: pointer;
		color: $background-color;
		background: $purple;
		// box-shadow: 1px 1px 2px black, 0 0 25px $purple, 0 0 5px $purple;
	}
}

.btn-coming-soon {
	color: $white;
	text-transform: uppercase;
	padding: 20px 0;
	font-size: 14px;
	font-size: 14px;
}

.slick-prev:before,
.slick-next:before {
	display: none;
}

.section-title {
	h2 {
		margin-bottom: 60px;
	}
}

#partners {
	padding-bottom: 100px;
	background: url("../images/bottom-gradient.png") 50% 25% no-repeat;
	background-size: cover;

	@media (max-width: $screen-xs-max) {
		padding-bottom: 60px;
	}

	img {
		width: 60%;
	}
}

ul.partner-logos {
	li {
		margin-left: 10px;
		height: 60px;
		display: flex !important;
		align-items: center;
		align-self: center;
		justify-content: center;

		a {
			transition: all 200ms cubic-bezier(0.4, 0, 0.23, 1);
		}
		a:hover {
			transform: scale(1.1);
		}
	}
	.slick-dots {
		display: flex;
		width: 50px;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		bottom: -20px;
	}
	.slick-list {
		padding-bottom: 20px;
	}
	padding-left: 0;
}

.card {
	background-color: $card-bg;
	border-radius: 25px;

	@media (max-width: $screen-xs-max) {
		margin-bottom: 40px;
	}

	h5.card-title {
		color: $white;
	}

	.card-body {
		@media (max-width: $screen-xs-max) {
			text-align: center;
		}
	}
	h5.card-title {
		margin-bottom: 24px;
	}

	p.card-text {
		margin-bottom: 24px;
		font-size: 14px;
	}
}

#see-all {
	text-align: center;
	padding: 50px 0;
}

#pre-footer {
	padding: 50px 0;
	background: $pre-footer-bg;

	.newsletter-title {
		h4 {
			@media (max-width: $screen-xs-max) {
				text-align: center;
			}
		}
	}

	.col {
		@media (max-width: $screen-xs-max) {
			width: 100%;
			flex: none;
			text-align: center;
		}
	}

	.mb-3 {
		@media (min-width: $screen-sm-min) {
			padding-right: 40px;
		}
	}

	h4 {
		color: $white;
		margin-bottom: 25px;
		font-size: 20px;
	}

	h5 {
		color: $white;
		margin-bottom: 40px;
		font-size: 20px;
	}

	input {
		color: white;
		background: $black;
		border: none;
		padding: 0 20px;
	}

	.btn-purple-fill {
		background: $purple;
		border-radius: 0 10px 10px 0;
		color: $white;
		text-transform: uppercase;
		padding: 10px 20px;
		font-size: 14px;
		font-size: 14px;

		&:hover,
		&:active {
			color: $white;
			// box-shadow: 1px 1px 2px black, 0 0 25px $purple, 0 0 5px $purple;
			background: darken($purple, 15%);
		}
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			padding-bottom: 10px;

			a {
				color: $grey;
				font-size: 14px;
				text-decoration: none;
				transition: opacity 0.15s ease-in-out, color 0.15s,
					background-color 0.15s ease-in-out;

				&:hover {
					opacity: 1;
					font-weight: 400;
					color: $purple;
				}
			}
		}
	}

	.social-icons {
		img {
			height: 22px;
			margin-right: 10px;

			&:hover,
			&:active {
				filter: sepia(50%) saturate(2000%) brightness(70%) hue-rotate(214deg);
			}
		}
	}
}

footer {
	padding: 30px 0;
	background: $black;

	p {
		color: $footer-text-color;
		opacity: 0.4;
		margin-top: 0;
		margin-bottom: 0;
	}

	img {
		width: 40%;
	}

	a {
		color: $grey;
		font-size: 14px;
		text-decoration: none;
		transition: opacity 0.15s ease-in-out, color 0.15s,
			background-color 0.15s ease-in-out;

		&:hover {
			opacity: 1;
			font-weight: 400;
			color: $purple;
		}
	}
}

.img-cards {
	width: 90%;

	@media (max-width: $screen-xs-max) {
		display: flex !important;
		align-items: center;
		align-self: center;
		justify-content: center;
	}
}

.rflex {
	display: flex !important;
	align-items: center;
	align-self: center;
	justify-content: center;
}

#blogPosts {
	padding-bottom: 20px;
	.slick-dots {
		bottom: -20px;
	}
}

.loader {
	display: none;
}

.spinner-border {
	display: none;
	height: 1rem;
	width: 1rem;
	margin-bottom: 3px;
	vertical-align: middle;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border 0.75s linear infinite;
	animation: spinner-border 0.75s linear infinite;

	&.loading {
		display: inline-block;
	}
}

#thankYouPopup {
	z-index: 2000;
	background-color: rgba(0, 0, 0, 0.6);

	.modal.show {
		display: flex;
		flex-direction: column;
	}

	.modal-header {
		border: 0px;
		background-color: black;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

		button:focus {
			outline: none;
		}
		button:hover {
			filter: brightness(0.9);
		}

		button.close {
			position: absolute;
			z-index: 1;
			float: right;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 1;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: 0.5;
			right: 10%;
			top: 10%;
			color: white;
			background-color: transparent;
			border: 0;
			padding: 1rem 1rem;
			margin: -1rem -1rem -1rem auto;
		}
	}

	.thank-you-popup {
		width: 100%;
		padding: 20px;
		text-align: center;

		img {
			display: block;
			width: 80px;
			height: auto;
			margin: 0 auto;
			margin-bottom: 25px;
		}
		p {
			font-size: 18px;
			margin-bottom: 27px;
			color: white;
		}
		h2 {
			color: $purple;
		}

		a.btn {
			&:hover {
				opacity: 1;
			}
		}
	}
	.modal-dialog {
		margin: auto;
	}
	.modal-content {
		background-color: transparent;
	}

	.modal-body {
		background-color: black;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.modal-backdrop.show {
		opacity: 0;
	}
}

thead, tbody, tfoot, tr, td, th {
    border-color: white;
    border-style: solid;
    border-width: 1px;
}

td {
	padding: 10px;
}
table {
	margin-bottom: 20px;
}
#ether-nav {
	text-align: right;
}

@media (max-width: $screen-xx-max) {
	.offcanvas-collapse {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
		overflow-y: auto;
		visibility: hidden;
		max-height: 0 !important;
		background-color: $black;
		transition: max-height 0.3s ease-in-out, visibility 1s ease-in-out;
	}

	.offcanvas-collapse.open {
		visibility: visible;
		max-height: 1000px !important;
	}
}

.nav-scroller {
	position: relative;
	z-index: 2;
	height: 2.75rem;
	overflow-y: hidden;
}

.nav-scroller .nav {
	display: flex;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	margin-top: -1px;
	overflow-x: auto;
	color: rgba(255, 255, 255, 0.75);
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
	height: 80px;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	font-size: 0.875rem;
	color: #6c757d;
}

.nav-underline .nav-link:hover {
	color: #007bff;
}

.nav-underline .active {
	font-weight: 500;
	color: #343a40;
}

.navbar {
	@media (max-width: $screen-xx-max) {
		min-height: 78px;
	}

	@media (min-width: $screen-sm-min) {
		padding: 0 !important;
	}

	.navbar-toggler {
		&:active {
			opacity: 0.8;
			transform: translateY(2px);
		}
		&:focus {
			box-shadow: unset !important;
		}
	}
	.navbar-brand {
		min-height: 78px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.bg-dark {
	background-color: $black !important;
}

nav {
	.logo {
		img {
			max-height: 25px;
		}
	}

	.dropdown-item {
		&.active {
			color: $purple;
			text-decoration: none;
			background-color: transparent;
		}
	}

	.nav-item {
		&.active {
			border-bottom: 2px solid $purple;
		}
	}

	a.nav-link {
		font-family: $poppins;
		color: $menu-grey;
		text-transform: uppercase;
		border-bottom: 2px solid transparent;

		@media (min-width: $screen-sm-min) {
			padding: 26px 0;
		}

		&:hover {
			color: $white !important;
			// border-bottom: 2px solid $purple;
			// box-shadow: 0 5px 5px -5px $purple;
		}

		&:active {
			color: $purple;
		}
	}

	ul li a {
		font-family: $poppins;
		font-weight: $font-weight-regular;
		color: $white;

		@media (min-width: $screen-xx-max) {
			margin: 0 10px;
		}
	}

	.btn {
		border: 1px solid $purple-light;
		border-radius: 10px;
		width: 120px;
		height: 40px;
		margin: 0 0 0 10px;
		color: $purple-light;
		font-weight: $font-weight-semibold;

		&:hover {
			background: $purple-dark;
			color: $white;
			border: 1px solid $purple-dark;
		}

		&.btn-primary {
			background: $purple-light;
			color: $white;

			&:hover {
				background: $purple-dark;
				color: $white;
				border: 1px solid $purple-dark;
			}
		}
	}

	.dropdown-item {
		color: $menu-grey;

		&:focus,
		&:hover {
			width: auto;
			color: $purple;
			background: none;
			font-weight: $font-weight-medium;
		}
	}
}

.dropdown-menu {
	background-color: $black;

	&[data-bs-popper] {
		@media (min-width: $screen-sm-min) {
			margin-top: 0;
		}
	}

	@media (max-width: $screen-xx-max) {
		text-align: right !important;
		border-top: 1px solid $purple;
		border-bottom: 1px solid $purple;
		border-left: 0px !important;
		border-right: 0px !important;
	}
}

.dropdown {
	&:hover .dropdown-menu {
		display: block;
		margin-top: 0.125rem;
	}
}

/* Dots */

$slick-dot-size: 24px;

$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

$slick-dot-color-active: $white;
$slick-opacity-default: 1 !default;

$slick-dot-character: "\25A0";
$slick-dot-character-hollow: "\25A1";

$dots-margin-bottom: 20px;
$dots-margin-bottom-mobile: 0px;

$slick-dot-color: $white;

.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: $dots-margin-bottom;
	@media (max-width: $screen-xs-max) {
		bottom: $dots-margin-bottom-mobile;
	}
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: 20px !important;
		width: 20px !important;
		margin: 0 2px; //changed
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:hover,
			&:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character-hollow;
				width: 20px;
				height: 20px;
				font-size: $slick-dot-size;
				line-height: 20px;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
			content: $slick-dot-character;
		}
	}
}
